import React from "react";
import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import styled from "@emotion/styled";
import colors from "styles/colors";
import PropTypes from "prop-types";

const ResourcesCardContainer = styled(Link)`
    border: 1px solid ${colors.grey200};
    padding: 3em 2.5em 2.25em 2.5em;
    border-radius: 3px;
    text-decoration: none;
    color: currentColor;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.06);
    transition: all 150ms ease-in-out;

    &:hover {
        box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
        transition: all 150ms ease-in-out;
        cursor: pointer;

        .ResourcesCardAction {
            color: ${colors.blue500};
            transition: all 150ms ease-in-out;

            span {
                transform: translateX(0px);
                opacity: 1;
                transition: transform 150ms ease-in-out;
            }
        }
    }
`

// const ResourcesCategory = styled("h6")`
//     font-weight: 600;
//     color: ${colors.grey600};
// `

const ResourcesTitle = styled("h3")`
    margin: 0;
    margin-top: 0.5em;
`

const ResourcesDescription = styled("div")`
    margin-top: 2em;
    margin-bottom: 4em;

    p:last-of-type {
        margin: 0;
    }
`

const ResourcesCardAction = styled("div")`
    font-weight: 600;
    text-decoration: none;
    color: currentColor;
    transition: all 150ms ease-in-out;

    span {
        margin-left: 1em;
        transform: translateX(-8px);
        display: inline-block;
        transition: transform 400ms ease-in-out;
    }
`

const ResourcesCard = ({ title, description, uid}) => {
    return(
    <ResourcesCardContainer className="ResourcesCard" to={`/resources/${uid}`}>
        <ResourcesTitle>
            {title[0].text}
        </ResourcesTitle>
        <ResourcesDescription>
            {RichText.render(description)}
        </ResourcesDescription>
        <ResourcesCardAction className="ResourcesCardAction">
            See resources <span>&#8594;</span>
        </ResourcesCardAction>
    </ResourcesCardContainer>
)
}

export default ResourcesCard;

ResourcesCard.propTypes = {
    category: PropTypes.array,
    date: PropTypes.string.isRequired,
    title: PropTypes.array.isRequired,
    description: PropTypes.array,
    uid: PropTypes.string.isRequired
}