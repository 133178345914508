import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import Layout from "components/Layout";
import TalkCard from "components/TalkCard";
import ResourcesCard from "components/ResourcesCard";
import TranscriptCard from "components/TranscriptCard";

const SpeakingTitle = styled("h1")`
    margin-bottom: 1em;
`

const SectionTitle = styled("h2")`
    margin: 3em auto 1em auto;
`

const SpeakingGrid = styled("div")`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2.5em;

    @media(max-width: 1050px) {
        grid-gap: 1.5em;
    }

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        grid-gap: 1.5em;
    }
`

const ResourcesGrid = styled("div")`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.5em;

    @media(max-width: 1050px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5em;
    }

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        grid-template-columns: 1fr;
        grid-gap: 2.5em;
    }
`

const SpeakingAction = styled(Link)`
    grid-column: 3;
    font-weight: 600;
    text-decoration: none;
    color: currentColor;
    transition: all 150ms ease-in-out;
    margin-left: auto;
    margin-top: 0.5em;

    @media(max-width: 1050px) {
        grid-column: 1;
        margin-left: 0;
    }

    @media(max-width:${dimensions.maxwidthTablet}px) {
        grid-column: 1;
        margin-left: 0;
    }

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        grid-template-columns: 1fr;
        margin-left: auto;
    }

    span {
        margin-left: 1em;
        transform: translateX(-8px);
        display: inline-block;
        transition: transform 400ms ease-in-out;
    }

    &:hover {
        color: ${colors.blue500};
        transition: all 150ms ease-in-out;

        span {
            transform: translateX(0px);
            opacity: 1;
            transition: transform 150ms ease-in-out;
        }
    }
`

const Speaking = ({ talks, workshops, engagements, resources, transcripts, meta }) => (
    <>
        <Helmet
            title={`Speaking | Carolyn Stransky`}
            htmlAttributes={{ lang: "en" }}
            link={[
                {
                    rel: `canonical`,
                    href: `https://workwithcarolyn.com/speaking`
                }
            ]}
            meta={[
                {
                    name: `description`,
                    content: `Conference talks and podcasts that I've been part of.`,
                },
                {
                    property: `og:title`,
                    content: `Speaking | Carolyn Stransky`,
                },
                {
                    property: `og:description`,
                    content: `Conference talks and podcasts that I've been part of.`,
                },
                {
                    name: `og:image`,
                    content: `https://user-images.githubusercontent.com/26869552/80156613-a8c83a00-85c4-11ea-98a6-45ecad014bcd.png`,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:creator`,
                    content: meta.author,
                },
                {
                    name: `twitter:title`,
                    content: `Speaking | Carolyn Stransky`,
                },
                {
                    name: `twitter:description`,
                    content: `Conference talks and podcasts that I've been part of.`,
                },
                {
                    name: `twitter:image`,
                    content: `https://user-images.githubusercontent.com/26869552/80156613-a8c83a00-85c4-11ea-98a6-45ecad014bcd.png`,
                }
            ].concat(meta)}
        />
        <Layout>
            <SpeakingTitle>
                Speaking
            </SpeakingTitle>
            <SectionTitle>
                Conference talks
            </SectionTitle>
            <SpeakingGrid>
                {talks.map((talk, i) => (
                    <TalkCard
                        key={i}
                        title={talk.node.talk_title}
                        emoji={talk.node.talk_preview_emoji}
                        destination="speaking"
                        uid={talk.node._meta.uid}
                    />
                ))}
            </SpeakingGrid>
            <SectionTitle>
                Workshops
            </SectionTitle>
            <SpeakingGrid>
                {workshops.map((workshop, i) => (
                    <TalkCard
                        key={i}
                        title={workshop.node.workshop_title}
                        emoji={workshop.node.workshop_preview_emoji}
                        destination="workshop"
                        uid={workshop.node._meta.uid}
                    />
                ))}
            </SpeakingGrid>
            <SectionTitle>
                Other engagements
            </SectionTitle>
            <SpeakingGrid>
                {engagements.map((engagement, i) => (
                    <TalkCard
                        key={i}
                        title={engagement.node.engagement_title}
                        emoji={engagement.node.engagement_preview_emoji}
                        destination="speaking"
                        uid={engagement.node._meta.uid}
                    />
                ))}
            </SpeakingGrid>
            <SectionTitle>
                Resources and slides
            </SectionTitle>
                <ResourcesGrid>
                    <ResourcesCard
                        title={resources[0].node.resources_title}
                        description={resources[0].node.resources_preview_description}
                        uid={resources[0].node._meta.uid}
                    />
                    <ResourcesCard
                        title={resources[1].node.resources_title}
                        description={resources[1].node.resources_preview_description}
                        uid={resources[1].node._meta.uid}
                    />
                    <ResourcesCard
                        title={resources[2].node.resources_title}
                        description={resources[2].node.resources_preview_description}
                        uid={resources[2].node._meta.uid}
                    />
                    <SpeakingAction to={"/resources"}>
                        More talk resources <span>&#8594;</span>
                    </SpeakingAction>
                </ResourcesGrid>
            <SectionTitle>
                Transcripts
            </SectionTitle>
                <ResourcesGrid>
                    <TranscriptCard
                        title={transcripts[0].node.transcript_title}
                        description={transcripts[0].node.transcript_preview_description}
                        uid={transcripts[0].node._meta.uid}
                    />
                    <TranscriptCard
                        title={transcripts[1].node.transcript_title}
                        description={transcripts[1].node.transcript_preview_description}
                        uid={transcripts[1].node._meta.uid}
                    />
                    <TranscriptCard
                        title={transcripts[2].node.transcript_title}
                        description={transcripts[2].node.transcript_preview_description}
                        uid={transcripts[2].node._meta.uid}
                    />
                    <SpeakingAction to={"/transcripts"}>
                        More talk transcripts <span>&#8594;</span>
                    </SpeakingAction>
                </ResourcesGrid>
        </Layout>
    </>
);

export default ({ data }) => {
    const talks = data.prismic.allTalks.edges;
    const workshops = data.prismic.allWorkshops.edges;
    const engagements = data.prismic.allEngagements.edges;
    const resources = data.prismic.allResourcess.edges;
    const transcripts = data.prismic.allTranscripts.edges;
    const meta = data.site.siteMetadata;
    if (!talks) return null;

    return (
        <Speaking talks={talks} workshops={workshops} engagements={engagements} resources={resources} transcripts={transcripts} meta={meta}/>
    )
}

Speaking.propTypes = {
    talks: PropTypes.array.isRequired,
    workshops: PropTypes.array.isRequired,
    resources: PropTypes.array.isRequired,
    transcripts: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
};


export const query = graphql`
    {
        prismic {
            allTalks(sortBy: talk_date_DESC) {
                edges {
                    node {
                        talk_title
                        talk_preview_emoji
                        _meta {
                            uid
                        }
                    }
                }
            }
            allWorkshops(sortBy: workshop_date_ASC) {
                edges {
                    node {
                        workshop_title
                        workshop_preview_emoji
                        _meta {
                            uid
                        }
                    }
                }
            }
            allEngagements(sortBy: engagement_date_DESC) {
                edges {
                    node {
                        engagement_title
                        engagement_preview_emoji
                        _meta {
                            uid
                        }
                    }
                }
            }
            allResourcess(sortBy: resources_date_DESC) {
                edges {
                    node {
                        resources_title
                        resources_preview_description
                        _meta {
                            uid
                        }
                    }
                }
            }
            allTranscripts(sortBy: transcript_date_DESC) {
                edges {
                    node {
                        transcript_title
                        transcript_preview_description
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`

