import React from "react";
import { Link } from "gatsby";
// import { RichText } from "prismic-reactjs";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import PropTypes from "prop-types";

const TalkCardContainer = styled(Link)`
    border: 1px solid ${colors.grey200};
    padding: 1em 1em 1em 1em;
    border-radius: 3px;
    text-decoration: none;
    color: currentColor;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.06);
    transition: all 150ms ease-in-out;

    span {
        margin-left: 1em;
        transform: translateX(-8px);
        display: inline-block;
        transition: transform 400ms ease-in-out;
    }

    &:hover {
        box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
        transition: all 150ms ease-in-out;
        cursor: pointer;

        .TalkAction {
            color: ${colors.blue500};
            transition: all 150ms ease-in-out;

            .TalkArrow {
                visibility: visible;
                transform: translateX(0px);
                opacity: 1;
                transition: transform 150ms ease-in-out;
            }
        }
    }

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        span {
            margin-left: 0.5em;
        }
    }
`

const TalkTitle = styled("h3")`
    margin: 0;
    margin-top: 0.5em;

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        font-size: 1em;
    }
`

const TalkCardAction = styled("div")`
    font-weight: 600;
    text-decoration: none;
    color: currentColor;
    transition: all 150ms ease-in-out;

    .TalkArrow {
        visibility: hidden;
        margin-left: 1em;
        transform: translateX(-8px);
        display: inline-block;
        transition: transform 400ms ease-in-out;
    }

    .TalkEmoji {
        margin-right: 0.5em;
        
        @media(max-width: ${dimensions.maxwidthMobile}px) {
            display: inline-block;
            margin-right: 0;
        }
    }
`

const TalkCard = ({ title, emoji, destination, uid}) => (
    <TalkCardContainer className="TalkCard" to={`/${destination}/${uid}`}>
        <TalkCardAction className="TalkAction">
        <TalkTitle class="TalkTitle">
            <span className="TalkEmoji">{emoji[0].text}</span> {title[0].text} <span className="TalkArrow">&#8594;</span>
        </TalkTitle>
        </TalkCardAction>
    </TalkCardContainer>
);

export default TalkCard;

TalkCard.propTypes = {
    date: PropTypes.string,
    title: PropTypes.array.isRequired,
    emoji: PropTypes.array.isRequired,
    destination: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired
}